/**
 * @description 订单
*/
export default $axios => ({
    // 获取卡片类型
    getCardIcon(params) {
        return $axios.get(`/mall/v1/credit/getCreditTypeList`, {
            params
        })
    },
    // 获取卡片规则
    getCardRules(params) {
        return $axios.get(`/mall/v1/credit/getCardBinRule`, {
            params
        })
    },
    // 获取卡片列表
    getCardList(params) {
        return $axios.get(`/mall/v1/user/listCreditCard`, {
            params
        })
    },
    // 获取弃单信息
    getAbandonOrder(params) {
        return $axios.post('/mall/v1/module/order/v1/orderDetail/getByOrderId', {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // 获取下单数据
    getOrder(params, hasLoading = true,header) {
        return $axios.post('/mall/v3/order/getOrderAmtDetail', {
            ...params
        }, {
            headers: {
                ...header
            },
            config: {
                hasLoading
            }
        })
    },
    // 搜索订单列表
    getOrderUserList(params) {
        return $axios.get(`/mall/v1/order/searchUserOrder`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    // 获取订单详情
    getOrderInfo(params) {
        return $axios.get(`/mall/v1/order/userOrderDetail`, {
            params
        })
    },
    // 获取下单数据
    getOrderList(params) {
        return $axios.get('/mall/v1/module/user/v3/order/userOrderList', {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    // 获取礼品卡订单列表
    getGiftCardOrderList(params) {
        return $axios.get('/mall/v1/module/user/v1/order/userGiftCardOrderList', {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    // 下单前获取是否需要验证
    getVerifyStatus(params,headers) {
        return $axios.get(`/mall/v1/module/sms/v1/verifyOrder/checkVerifyStatus`, {
            params,
            headers:headers,
            config: {
                // hasLoading: true,
                // loadingType: 2
            }
        })
    },
    // 获取短信验证码
    getVerifyCode(params) {
        return $axios.post('/mall/v1/module/sms/v1/verifyOrder/sendVerificationCode', {
            ...params
        })
    },
    // 物流查询
    setTrackSearch(params) {
        return $axios.get(`/mall/v1/order/orderTrackingInfo`, {
            params,
            config: {
                hasLoading: true
            }
        })
    },
    // 短信验证码验证
    setCheckVerifyCode(params) {
        return $axios.post('/mall/v1/module/sms/v1/verifyOrder/verify', {
            ...params
        })
    },
    // 下单前校验是否重复下单
    checkRepeatCreateOrder(params, hasLoading = false,headers) {
        return $axios.post('/mall/v1/order/v1/check/repeat', {
            ...params
        }, {
            headers:headers,
            config: {
                // hasLoading,
                // loadingType: 2
            }
        })
    },
    // 创建订单
    setCreateOrder(params, hasLoading = false,headers) {
        return $axios.post('/mall/v2/order/createMallOrder', {
            ...params
        }, {
            headers:headers,
            config: {
                hasLoading,
                loadingType: 2
            }
        })
    },
    // 下单商品库存校验
    setCheckStockOrder(params, hasLoading = false) {
        return $axios.post('/mall/v1/module/order/v1/orderSku/checkQuantity', {
            ...params
        }, {
            config: {
                // hasLoading,
                // loadingType: 2
            }
        })
    },
    // 订单商品重新加购
    setOrderRepurchase(params) {
        return $axios.post('/mall/v2/cart/addCartByOrder', {
            ...params
        }, {
            config: {
                hasLoading: true,
            }
        })
    },
    // payssion 支付
    setPaySSionPay(params,headers) {
        return $axios.post('/mall/v1/pay/createPayssion', {
            ...params
        }, {
            headers:headers,
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // payssion 支付回调接口
    getPaySSionPay(params) {
        return $axios.post('/mall/v1/pay/getPayDetails', {
            ...params
        }, {
            config: {
                hasLoading: true,
                loadingType: 2,
                hasToast:true
            }
        })
    },
    // Airwallex 空中云汇支付
    setAWPay(params) {
        return $axios.post('/mall/v1/pay/createAw', {
            ...params
        }, {
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // Airwallex 空中云汇支付回调接口
    getAWPay(params) {
        return $axios.post('/mall/v1/pay/notifyAw', {
            ...params
        }, {
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // zip 分期支付
    setZipPay(params) {
        return $axios.post('/mall/v1/pay/zipPay/createZipPay', {
            ...params
        }, {
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // klana 支付
    setKlaNaPay(params) {
        return $axios.post('/mall/v1/pay/stripelink/payment/session', {
            ...params
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            config: {
                hasLoading: true,
                loadingType: 2,
                dataType: 2
            }
        })
    },
    // Paypal 支付
    setPayPalPay(params,headers) {
        return $axios.post('/mall/v1/pay/paypalPayment', {
            ...params
        }, {
            headers:headers,
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // Paypal快捷支付
    createExpressPaypalOrder(params) {
        return $axios.post('/mall/v2/order/createExpressPaypalOrder', {
            ...params
        }, {
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // 信用卡支付 支付
    setStripePay(params) {
        return $axios.post('/mall/v1/pay/stripePayment', {
            ...params
        }, {
            config: {
                hasLoading: true,
                loadingType: 2
            }
        })
    },
    // 获取礼品卡下单信息
    getGiftCardOrder(params) {
        return $axios.post(`/mall/v1/gift/card/calcGiftCardOrder`, {
            ...params
        })
    },
    // 创建订单-礼品卡
    setCreateGiftCardOrder(params) {
        return $axios.post('/mall/v1/order/createGiftCardOrder', {
            ...params
        })
    },
    // 获取快捷支付参数
    createIntent(params) {
        return $axios.post('/mall/v1/order/createStripeFastOrder', {
            ...params
        })
    },
});