export default $axios => ({
    // 获取优惠券数量
    getCouponNum() {
        return $axios.get(`/mall/v1/coupon/countAvaliableCoupon`);
    },
    // 获取优惠券列表
    getCouponList() {
        return $axios.get(`/mall/v1/coupon/userCouponList`);
    },
    // 获取优惠券可用商品
    getCouponProduct(params) {
        return $axios.get(`/mall/v1/coupon/couponProduct`, {
            params,
        });
    },
    // 获取优惠券列表
    getCouponReceiveList() {
        return $axios.get(`/mall/v1/coupon/getReceiveCouponList`, {
            config: {
                hasLoading: true
            }
        });
    },
    // 获取落地优惠券配置信息
    getCouponConfig() {
        return $axios.get(`/mall/v1/coupon/getReceiveCouponCenterPage`);
    },
    // 自动获取优惠码
    getAutoCoupon(params) {
        return $axios.post('/mall/v1/coupon/v1/coupon/autoApply', {
            ...params
        })
    },
    // 领取优惠券
    getAcquireCoupon(params,header) {
        return $axios.post('/mall/v1/coupon/acquireCoupon', {
            ...params
        }, {
            headers: {
                "Content-Type": "application/json",
                ...header
            },
            config: {
                hasLoading: true,
                dataType: 2
            }
        })
    },
    // 扫码领取优惠券
    getQrCodeCoupon(params) {
        return $axios.post('/mall/v1/coupon/scanCodeCollection', {
            ...params
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            config: {
                hasLoading: true,
            }
        })
    },
    // 优惠券选择
    getChooseCoupon(params, hasLoading = true) {
        return $axios.post('/mall/v1/coupon/orderCouponList', {
            ...params
        }, {
            headers: {
                "Content-Type": "application/json",
            },
            config: {
                hasLoading,
                dataType: 2
            }
        })
    },
    // 获取过期优惠券数量
    getExpireCoupon(params) {
        return $axios.get(`/mall/v1/coupon/userCouponExpireWarn`);
    }
  });